import auth from '@/middleware/auth.js'

export default [
	{
		path: ''
		,component: () => import('@/layouts/Constelacion')
		,children: [
			{
				path: '/tesoreria'
				,name: 'tesoreria'
				,component: () => import('@/apps/tesoreria/pages/Dashboard')
				,meta: {
					title: 'Tesorería'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/empresas'
				,name: 'tesoreria_empresas'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Empresa')
				,meta: {
					title: 'Empresas - Tesorería'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/facturas'
				,name: 'tesoreria_facturas'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Factura')
				,meta: {
					title: 'Facturas - Tesorería'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/facturas_invalidas'
				,name: 'tesoreria_facturas_invalidas'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/FacturaInvalida')
				,meta: {
					title: 'Facturas Inválidas - Tesorería'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/proveedores'
				,name: 'tesoreria_proveedores_admin'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Proveedor')
				,meta: {
					title: 'Proveedores - Tesorería'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/autorizadores'
				,name: 'tesoreria_autorizadores'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Autorizador')
				,meta: {
					title: 'Autorizadores - Tesorería'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/preautorizacion'
				,name: 'tesoreria_preautorizaciones'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Preautorizacion')
				,meta: {
					title: 'Preautorización - Tesorería'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/autorizacion'
				,name: 'tesoreria_autorizaciones'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Autorizacion')
				,meta: {
					title: 'Autorización - Tesorería'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/directivos'
				,name: 'tesoreria_autorizaciones_directivos'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Directivos')
				,meta: {
					title: 'Autorización Directivos - Tesorería'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/configuracion'
				,name: 'tesoreria_configuracion'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Configuracion')
				,meta: {
					title: 'Configuración - Tesorería'
					,middleware: auth
				}
			}

			//Proveedores
			,{
				path: '/proveedores'
				,name: 'proveedores'
				,component: () => import('@/apps/tesoreria/pages/Dashboard')
				,meta: {
					title: 'Dashboard'
					,middleware: auth
				}
			}
			,{
				path: '/proveedores/facturas'
				,name: 'proveedores_facturas'
				,component: () => import('@/apps/tesoreria/pages/Proveedores/Facturas')
				,meta: {
					title: 'Dashboard'
					,middleware: auth
				}
			}
			,{
				path: '/proveedores/empresa/:valida?'
				,name: 'proveedores_empresa'
				,component: () => import('@/apps/tesoreria/pages/Proveedores/Empresa')
				,meta: {
					title: 'Dashboard'
					,middleware: auth
				}
			}
			,{
				path: '/prueba_facturas'
				,name: 'pruebas_facturas'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Facturacion')
				,meta: {
					title: 'Facturas'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/gastos'
				,name: 'tesoreria_gastos'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Gasto')
				,meta: {
					title: 'Gastos'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/autorizaciones/gastos'
				,name: 'tesoreria_autorizaciones_gastos'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Gasto')
				,meta: {
					title: 'Gastos'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/directivos/gastos'
				,name: 'tesoreria_directivos_gastos'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Gasto')
				,meta: {
					title: 'Gastos'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/catalogos'
				,name: 'tesoreria_catalogos'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Catalogo')
				,meta: {
					title: 'Catálogo'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/coi'
				,name: 'tesoreria_coi'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Coi')
				,meta: {
					title: 'COI'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/viaticos'
				,name: 'tesoreria_viaticos'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/Viatico')
				,meta: {
					title: 'Viatico'
					,middleware: auth
				}
			}
			,{
				path: '/tesoreria/autorizaciones/viaticos'
				,name: 'tesoreria_autorizaciones_viaticos'
				,component: () => import('@/apps/tesoreria/pages/Tesoreria/AutorizacionViatico')
				,meta: {
					title: 'Autorizaciones Viaticos'
					,middleware: auth
				}
			}
		]
	}
]